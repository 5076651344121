<template>
  <page-wrapper>
    <template v-slot:before_content>
      <user-info class="pt-5" />
    </template>
    <template v-slot:content>
      <h2 class="h2 h2_bold mb-3">Как это работает?</h2>
      <p class="mb-1">
        У&#160;вас есть свой сайт, сообщество в&#160;социальных сетях, у&#160;вас много подписчиков или популярный канал на&#160;Ютуб?
      </p>
      <p class="mb-1">
        Партнёрам Наложки доступны простые в&#160;работе и&#160;эффективные инструменты заработка.
      </p>
      <p>
        Вы делитесь своей персональной ссылкой или размещаете баннер и&#160;получаете вознаграждение за&#160;каждую
        завершённую сделку, созданную по&#160;вашей ссылке.
      </p>
      <p>
        Внимание! Партнерский кабинет работает в&#160;пилотном режиме, поэтому некоторые возможности пока недоступны.
        По&#160;вопросам выплат, предоставления реквизитов для&#160;перечисления просим писать в&#160;чат поддержки или
        на&#160;электронную почту <a href="mailto:partners@nalozhka.ru">partners@nalozhka.ru</a>.
      </p>

      <h2 class="h2 h2_bold mt-5 mb-1">Инструменты</h2>
      <p class="light mb-3">Все необходимые форматы, лёгкая настройка, высокая конверсия.</p>
      <div class="row mb-3">
        <div class="col-12 col-lg-6">
          <a href="javascript:void(0);" class="button" @click="goInstruments('InstrumentsTab')">Персональные ссылки</a>
        </div>
        <div class="col-12 col-lg-6 mt-5 mt-lg-0">
          <a href="javascript:void(0);" class="button" @click="goInstruments('BannersTab')">Баннеры</a>
        </div>
      </div>
    </template>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@/components/common/PageWrapper'
import UserInfo from '@/components/common/UserInfo'

export default {
  name: 'Main',
  components: {
    PageWrapper,
    UserInfo
  },
  methods: {
    goInstruments (prop) {
      this.$router.push({
        name: 'Instruments',
        params: { prop }
      })
    }
  }
}
</script>
